import PropTypes from "prop-types"
import React from "react"
import TextField from "@material-ui/core/TextField"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { CompactPicker } from "react-color"
import moment from "moment"
import loadable from "@loadable/component";
const FontPicker = loadable(() => import("font-picker-react"))

// const values = {}

class CounttoConfigForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            date: moment().add(1, "minute").toISOString(),
            color: "#fff",
            font: "Open Sans",
        }
    }

    render() {
        //
        const { onUpdate } = this.props
        const { title, date, color, font } = this.state

        return (
           <MuiPickersUtilsProvider utils={MomentUtils} locale={"en"}>

               <TextField
                  fullWidth
                  name="title"
                  label="Title"
                  value={title}
                  variant="outlined"
                  onChange={e => this.setState({ title: e.target.value }, () => onUpdate(this.state))}
                  style={{ marginBottom: "15px" }}
               />
               <div className="clearfix">
                   <div style={{ float: "left", width: "50%" }}>
                       <DateTimePicker
                          autoOk
                          disablePast
                          inputVariant="outlined"
                          minDate={new Date()}
                          value={date}
                          onChange={(value) => this.setState({ date: value.toISOString() }, () => onUpdate(this.state))}
                       />

                       {/**/}
                       <FontPicker
                          apiKey="AIzaSyDapCmOVRgg89vmZLNsSXUXqEaWDX1UXyg"
                          activeFontFamily={font}
                          onChange={newFont => {
                              this.setState({ font: newFont.family }, () => onUpdate(this.state));
                          }}
                       />

                   </div>
                   <div style={{ float: "right", width: "36%" }}>
                       <CompactPicker
                          color={color}
                          onChangeComplete={color => this.setState({ color: color.hex }, () => onUpdate(this.state))}
                       />
                   </div>
               </div>
           </MuiPickersUtilsProvider>
        )
    }
}

CounttoConfigForm.propTypes = {
    onUpdate: PropTypes.func,
}

CounttoConfigForm.defaultProps = {}

export default CounttoConfigForm