import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import CounttoConfigForm from "../components/countto-config-form"
import CounttoPreview from "../components/CounttoPreview"
// import CountdownConfigForm from "../components/countdown-config-form"
// import CountdownPreview from "../components/countdown-preview"
import Snackbar from "@material-ui/core/Snackbar"

export default class CountdownTo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            date: "",
            color: "",
            font: "",
            copiedToClipboard: false,
        };

        //
        this.onFormUpdate = this.onFormUpdate.bind(this)
    }

    onFormUpdate(values) {
        //
        this.setState(values)
    }

    render() {
        const { title, date, color, font, copiedToClipboard } = this.state

        return (
           <Layout>
               <SEO title="Twitch Countdown Overlay Configurator"/>
               <Typography component="h1">Countdown To</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   Here you're able to create an countdown overlay for your twitch stream.
                   <br/><br/>
                   Simply choose your target date and time in the form below and you can see the output in
                   the preview section under the form. Also your able to define the color for the text in the ticker. Just choose your
                   color and the preview will update accordingly with your new values.
               </Typography>
               <br/>
               <Typography component="h2">
                   Options
               </Typography>
               <br/>

               <Typography variant="body2" component="ul" style={{ marginLeft: "20px"}}>
                   <li>
                       <strong>Title</strong> -The title of your counter, leaf blank to hide.
                   </li>
                   <li>
                       <strong>Date & Time</strong> - The date and time the countdown should countdown to.
                   </li>
                   <li>
                       <strong>Color</strong> - The color used for the title and the counter.
                   </li>
                   <li>
                       <strong>Font</strong> - The font to render your ticker.
                   </li>
               </Typography>
               <br/>

               <CounttoConfigForm onUpdate={(values) => this.onFormUpdate(values)}/>

               <br/>

               <CounttoPreview
                   title={title}
                   date={date}
                   color={color}
                   font={font}
                   onCoppiedToClipboard={(widgetUrl) => {
                       this.setState({ copiedToClipboard: true }, () => {
                           setTimeout(() => {
                               this.setState({ copiedToClipboard: false })
                           }, 3000)
                       })
                   }}
               />

               {copiedToClipboard ?
                  <Snackbar
                     open={true}
                     anchorOrigin={{
                         vertical: "bottom",
                         horizontal: "left",
                     }}
                     message="Link copied!"
                     autoHideDuration={6000}
                  />
                  :
                  null
               }
           </Layout>
        )
    }
}